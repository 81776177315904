<template>
  <div class="companyList-container">
    <ion-icon v-if="page>1" class="switch-page-button previous-button" name="chevron-back-circle-outline"
              @click="previousPage()"></ion-icon>
    <ion-icon v-if="page<companies.length/8" class="switch-page-button next-button" name="chevron-forward-circle-outline"
              @click="nextPage()"></ion-icon>
    <div class="company-list-container">
      <ul
          :class="{
          'page-two': page==2,
          'page-three': page==3,
          'page-four': page==4,
          'page-five': page==5,
        }"
          class="company-list"
      >
        <li
            v-for="company in companies"
            :key="company.id"
            class="company-item"
            @click="handleClick(company)"
        >
          <div
              v-if="companiesCannotGetIcons.indexOf(company.name) === -1"
              class="round-image-container">
            <img
                :alt="company.name"
                :src="getIconSRC(company.name)"
                class="round-image"
            />
          </div>

          <div
              v-else-if='getIconPath(company.name)'>
            <img
                :alt="company.name"
                class="round-image"
                :src="getIconPath(company.name)"
            />
          </div>

          <div
              v-else>
            <img
                :alt="company.name"
                class="round-image"
                src="@/images/default-company-logo.png"
            />
          </div>


          <p :class="{'small-text':company.name.length > 10, 'tiny-text':company.name.length > 15}">{{
              company.name
            }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";
import {useRouter} from "vue-router";
import { companiesCannotGetIcons, getIconPath, getIconSRC} from '@/composables/WishList/getCompanyIcons'

export default {
  props: ["companies"],
  async setup(props) {
    const router = useRouter();
    const companies = ref(props.companies);
    const page = ref(1);

    const previousPage = () => {
      page.value--;
    }

    const nextPage = () => {
      page.value++;
    }

    const handleClick = (company) => {
      switch (router.currentRoute.value.name) {
        case "Junior-WishList":
          router.push({name: "Junior-WishCompany", params: {companyId: company.id, companyName: company.name}});
          break
        case "Senior-WishList":
          router.push({name: "Senior-WishCompany", params: {companyId: company.id, companyName: company.name}});
          break
      }
    }

    return {
      page,
      companies,
      companiesCannotGetIcons,
      getIconSRC,
      getIconPath,
      handleClick,
      previousPage,
      nextPage,
    }
  }

}

</script>

<style scoped>
.companyList-container {
  position: relative;
  animation: fadein 0.5s;
}

.company-list-container {
  position: relative;
  width: 100%;
  height: 20.5vh;
  overflow-x: hidden;
}

.switch-page-button {
  position: absolute;
  top: 8vh;
  color: grey;
  font-size: 200%;
  animation: fadein 0.5s;
  transition: color 0.25s ease;
}

.switch-page-button:hover {
  cursor: pointer;
  color: rgb(59, 59, 59);
  transition: color 0.25s ease;
}

.previous-button {
  left: -35px;
}

.next-button {
  right: -35px;
}

@media screen and (max-width: 768px) {
  .company-list-container {
    overflow-x: scroll;
  }

  .previous-button {
    display: none;
  }

  .next-button {
    display: none;
  }
}

.company-list {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 2vh;
  list-style: none;
  display: flex;
  transition: all 0.75s ease;
  overflow: hidden;
}

.page-two {
  left: -56vw;
}

.page-three {
  left: -112vw;
}

.page-four {
  left: -168vw;
}

.page-five {
  left: -224vw;
}

.company-item {
  padding: 1vh 2vh;
  flex: 0 0 16vh;
  height: 18vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 50%;
}

.company-item:hover {
  cursor: pointer;
  background-color: #f1f1f1;
}

.round-image-container {
  height: 12vh;
  width: 12vh;
  border-radius: 6vh;
  background-color: white;
}

.round-image {
  height: 12vh;
  width: 12vh;
  flex-grow: 1;
  padding: 3vh;
  background-color: white;
}

.default-image {
  height: 12vh;
  width: 12vh;
  background-color: white;
  border-radius: 50%;
  flex-grow: 1;
}

.company-item p {
  width: 100%;
  font-weight: 500;
}

.small-text {
  font-size: 70%;
}

.tiny-text {
  font-size: 50%;
}
</style>
